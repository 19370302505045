import { NgModule } from '@angular/core';
import { LuxonDiffPipe } from './pipes/luxon-diff.pipe';
import { LuxonToFormatPipe } from './pipes/luxon-to-format.pipe';
import { LuxonToLocaleStringPipe } from './pipes/luxon-to-locale-string.pipe';
import { LuxonIntervalPipe } from './pipes/luxon-interval.pipe';

@NgModule({
    imports: [LuxonDiffPipe, LuxonToFormatPipe, LuxonIntervalPipe, LuxonToLocaleStringPipe],
    exports: [LuxonDiffPipe, LuxonToFormatPipe, LuxonIntervalPipe, LuxonToLocaleStringPipe],
    providers: [LuxonToFormatPipe, LuxonToLocaleStringPipe, LuxonDiffPipe],
})
export class LuxonModule {}
